import type {CSSProperties, FC} from 'react';
import {memo} from 'react';
import {useDrag, useDrop} from 'react-dnd';

import {ItemTypes} from './ItemTypes';

const style: CSSProperties = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
};

interface IDataItem {
    id: string,
    groupId: string,
    text: string
}

export interface CardProps extends IDataItem{
    moveCard: (id: string, to: number) => void
    findCard: (id: string) => { index: number }
}

interface Item {
    id: string
    groupId: string
    originalIndex: number
}

export const Card: FC<CardProps> = memo(function Card({
    id,
    groupId,
    text,
    moveCard,
    findCard,
}) {
    const originalIndex = findCard(id).index;
    const [{isDragging}, drag] = useDrag(
        () => ({
            type: ItemTypes.CARD,
            item: {id, originalIndex, groupId},
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            // end: (item, monitor) => {
            //     const {id: droppedId, originalIndex} = item;
            //     const didDrop = monitor.didDrop();
            //     if (!didDrop) {
            //         moveCard(droppedId, originalIndex);
            //     }
            // },
        }),
        [id, originalIndex, moveCard],
    );

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.CARD,
            hover(overwrite: Item, monitor) {
                // console.log('monitor', );
                if (overwrite.id !== id && monitor.canDrop()) {
                    const {index: overIndex} = findCard(id);

                    moveCard(overwrite.id, overIndex);
                }
            },
            canDrop: item => {
                return item.groupId === groupId;
            },
        }),
        [findCard, moveCard],
    );

    const opacity = isDragging ? 0 : 1;
    return (
        <div ref={(node) => drag(drop(node))} style={{...style, opacity}}>
            [{groupId}] {text}
        </div>
    );
});
