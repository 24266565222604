import {useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import {getLineStyle} from '@acrool/react-gantt';



const padding = 40;
const preWidth = 20;
const strokeWidth = 2;

let startY = 50;
const timelines = [
    {sort: 'asc', x: '100px', width: '100px', height: '40px', color: 'rgba(88, 235, 255, 0.24)'},
    {sort: 'asc', x: '250px', width: '240px', height: '60px', color: 'rgba(255,88,88,0.24)'},
    {sort: 'asc', x: '400px', width: '350px', height: '40px', color: 'rgba(88,121,255,0.24)'},
    {sort: 'asc', x: '540px', width: '150px', height: '60px', color: 'rgba(91,253,37,0.24)'},
    {sort: 'asc', x: '200px', width: '220px', height: '40px', color: 'rgba(37,253,177,0.24)'},
    {sort: 'asc', x: '400px', width: '220px', height: '60px', color: 'rgba(190,37,253,0.24)'},
    {sort: 'asc', x: '400px', width: '250px', height: '40px', color: 'rgba(190,37,253,0.24)'},
    {sort: 'asc', x: '350px', width: '400px', height: '40px', color: 'rgba(190,37,253,0.24)'},

    // desc
    {sort: 'desc', x: '100px', width: '100px', height: '40px', color: 'rgba(88, 235, 255, 0.24)'},
    {sort: 'desc', x: '250px', width: '240px', height: '60px', color: 'rgba(255,88,88,0.24)'},
    {sort: 'desc', x: '400px', width: '350px', height: '40px', color: 'rgba(88,121,255,0.24)'},
    {sort: 'desc', x: '540px', width: '150px', height: '60px', color: 'rgba(91,253,37,0.24)'},
    {sort: 'desc', x: '200px', width: '220px', height: '40px', color: 'rgba(37,253,177,0.24)'},
    {sort: 'desc', x: '470px', width: '220px', height: '60px', color: 'rgba(190,37,253,0.24)'},
    {sort: 'desc', x: '400px', width: '250px', height: '40px', color: 'rgba(190,37,253,0.24)'},
];



function DragLine() {

    const [count, setCount] = useState(0);
    const linesRef = useRef<HTMLElement[]>(Array.from({length: timelines.length}));


    const args = timelines.map((row, index) => {
        if(timelines.length > index && linesRef?.current){
            if(row.sort === 'desc'){
                return getLineStyle(linesRef?.current[index+1], linesRef?.current[index]);
            }
            return getLineStyle(linesRef?.current[index], linesRef?.current[index + 1]);
        }
        return undefined;
    });


    return <DragLineRoot>

        <button type="button" onClick={() => setCount(curr => curr +1 )}>count{count}</button>

        {timelines.map((row, index) => {
            const y = startY + (padding * index);
            return <Timeline
                key={`timeline_${index}`}
                ref={(ref: any) => {
                    linesRef.current[index] = ref;
                }}
                style={{
                    left: row.x,
                    top: `${y}px`,
                    width: row.width,
                    // height: '60px'
                }}
            ><TimelineBox style={{
                    backgroundColor: row.color,
                }}>{index}</TimelineBox></Timeline>;
        })}


        {args
            .filter(args => args)
            .map((arg, index) => {

                return <AbsoluteSvg key={`linkSvg_${index}`} style={arg?.style}>
                    <polyline points={arg?.lines.map(line => `${line.x},${line.y}`).join(' ')} style={{
                        fill:'none',
                        stroke:'green',
                        strokeWidth: 2,
                    }} />
                    <path d={arg?.arrow} fill='red' stroke="none"/>
                    {arg?.category && <text x="10" y="20" fontSize={10} fontWeight={700} fill="#000">{arg?.category}</text>}
                </AbsoluteSvg>;
            })}


    </DragLineRoot>;

}

export default DragLine;



const TimelineBox = styled.div`
  border: 1px solid #2b3035;
  width: 100%;
  height: 25px;
`;

const AbsoluteLine = styled.div`
  position: absolute;
  background-color: #535bf2;
;
`;


const Timeline = styled.div`
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
`;

const AbsoluteSvg = styled.svg`
  position: absolute;
  background-color: rgba(255, 110, 110, 0.32);
`;

const DragLineRoot = styled.div`
  background-color: #72a975;
  width: 100%;
  height: 100%;

  position: relative;
  
  * {
    box-sizing: border-box;
  }
`;

