import {ETaskStatus, IGroup, ITaskData, EDataLevel} from '@acrool/react-gantt';


// #00bde5

const assignr = {
    imagine: {
        id: '01h4n8dd13',
        name: 'Imagine Chiu',
        avatarUrl: '/sample/avatar/female-1.jpg',
    },
    gary: {
        id: '01h4n7cc12',
        name: 'Gary Chien',
        avatarUrl: '/sample/avatar/female-2.jpg',
    },
    selin: {
        id: '01h4n7ee75',
        name: 'Selin Wu',
        avatarUrl: '/sample/avatar/female-3.jpg',
    },
    ken: {
        id: '01h4n8aa95',
        name: 'Ken Lin',
        avatarUrl: '/sample/avatar/female-4.jpg',
    }
};

// export const ganttData1: IData[] = [
//     {
//         'id': '01h40wr7tqyas5xsj78tysj9m3',
//         'text': 'RICH GAME Mobile 改版',
//         'dataLevel': EDataLevel.Project,
//         'children': [
//             {
//                 'id': '01gwcrb1yry5ka7g18j6kab2en@PM',
//                 'text': 'PM',
//                 'barColor': '#fa6120',
//                 'dataLevel': EDataLevel.Team,
//                 'children': [
//                     {
//                         'id': '01h40wt8kwnw8rez1yy9jbyhvr@PM',
//                         'text': '需求分析',
//                         'progressRate': 0,
//                         'estimateWorkTime': 16,
//                         'estimateStartDate': '2024-07-10',
//                         'estimateEndDate': '2024-07-20',
//                         'dataLevel': EDataLevel.Task,
//                         'status': ETaskStatus.ReadyStart,
//                         'links': ['01h412rzv4s5p7sdw5g435dwc0@PM']
//
//                     },
//                 ]
//             },
//         ]
//     },
//     {
//         'id': '01h63jvjbnzr7yx9483f0p34zb',
//         'text': '代理後台',
//         'dataLevel': EDataLevel.Project,
//         'milestones': [
//             {date: '2024-08-01', name: '需求洽談'},
//             {date: '2024-08-20', name: '結案日'},
//             {date: '2024-11-30', name: '請款日'}
//         ],
//         'children': [
//             {
//                 'id': '01gvz5dzfj2k361jba1brhnz0p',
//                 'text': 'Frontend',
//                 'dataLevel': EDataLevel.Team,
//                 'barColor': '#00bde5',
//                 'children': [
//                     {
//                         'id': '01h63pkhr2ymzknjk5a0vfj235_A1',
//                         'text': '新增 2024登入頁',
//                         // 'assigners': [assignr.imagine],
//                         'progressRate': 100,
//                         'estimateWorkTime': 4,
//                         'estimateStartDate': '2024-12-01',
//                         'estimateEndDate': '2024-12-08',
//                         'status': ETaskStatus.ReadyStart,
//                         'dataLevel': EDataLevel.Task,
//                     },
//                     {
//                         'id': '01h63pkhrr42egzm6f4dbc0geb_A1',
//                         'text': '新增 2024報表-佣金報表',
//                         'assigners': [assignr.imagine],
//                         'progressRate': 100,
//                         'estimateWorkTime': 16,
//                         'estimateStartDate': '2024-12-09',
//                         'estimateEndDate': '2024-12-14',
//                         'status': ETaskStatus.Processing,
//                         'dataLevel': EDataLevel.Task,
//                         'links': [
//                             '01h63pkhrwms9necc3veac6rc8',
//                         ]
//                     },
//                     {
//                         'id': '01h63pkhrwms9necc3veac6rc8_A1',
//                         'text': '新增 2024報表-存取款',
//                         'assigners': [assignr.gary],
//                         'progressRate': 40,
//                         'estimateWorkTime': 8,
//                         'estimateStartDate': '2024-12-09',
//                         'estimateEndDate': '2024-12-14',
//                         'dataLevel': EDataLevel.Task,
//                         // 'links': ['01h63pkhrr42egzm6f4dbc0geb']
//                     },
//                 ],
//             },
//         ]
//     }
// ];
// export const ganttData2: IData[] = [
//     {
//         'id': '01h40wr7tqyas5xsj78tysj9m3',
//         'text': 'RICH GAME Mobile 改版',
//         'dataLevel': EDataLevel.Project,
//         'children': [
//             {
//                 'id': '01gwcrb1yry5ka7g18j6kab2en@PM',
//                 'text': 'PM',
//                 'barColor': '#fa6120',
//                 'dataLevel': EDataLevel.Team,
//                 'children': [
//                     {
//                         'id': '01h40wt8kwnw8rez1yy9jbyhvr@PM',
//                         'text': '需求分析',
//                         'progressRate': 0,
//                         'estimateWorkTime': 16,
//                         'estimateStartDate': '2024-07-10',
//                         'estimateEndDate': '2024-07-20',
//                         'dataLevel': EDataLevel.Task,
//                         'status': ETaskStatus.ReadyStart,
//                         'links': ['01h412rzv4s5p7sdw5g435dwc0@PM']
//
//                     },
//                 ]
//             },
//         ]
//     },
//     {
//         'id': '01h63jvjbnzr7yx9483f0p34zb',
//         'text': '代理後台',
//         'dataLevel': EDataLevel.Project,
//         'children': [
//             {
//                 'id': '01gvz5dzfj2k361jba1brhnz0p',
//                 'text': 'Frontend',
//                 'dataLevel': EDataLevel.Team,
//                 'barColor': '#00bde5',
//                 'children': [
//                     {
//                         'id': '01h63pkhr2ymzknjk5a0vfj235_A2',
//                         'text': '新增 2025登入頁',
//                         // 'assigners': [assignr.imagine],
//                         'progressRate': 100,
//                         'estimateWorkTime': 4,
//                         'estimateStartDate': '2025-01-01',
//                         'estimateEndDate': '2025-01-08',
//                         'status': ETaskStatus.ReadyStart,
//                         'dataLevel': EDataLevel.Task,
//                         'links': [
//                             '01h63pkhrr42egzm6f4dbc0geb_A2',
//                             // '01h63pkhrwms9necc3veac6rc8'
//                         ]
//                     },
//                     {
//                         'id': '01h63pkhrr42egzm6f4dbc0geb_A2',
//                         'text': '新增 2025報表-佣金報表',
//                         'assigners': [assignr.imagine],
//                         'progressRate': 100,
//                         'estimateWorkTime': 16,
//                         'estimateStartDate': '2025-01-09',
//                         'estimateEndDate': '2025-01-14',
//                         'status': ETaskStatus.Processing,
//                         'dataLevel': EDataLevel.Task,
//                         'links': [
//                             '01h63pkhrwms9necc3veac6rc8',
//                         ]
//                     },
//                     {
//                         'id': '01h63pkhrwms9necc3veac6rc8_A2',
//                         'text': '新增 2025報表-存取款',
//                         'assigners': [assignr.gary],
//                         'progressRate': 40,
//                         'estimateWorkTime': 8,
//                         'estimateStartDate': '2025-02-09',
//                         'estimateEndDate': '2025-02-14',
//                         'dataLevel': EDataLevel.Task,
//                         // 'links': ['01h63pkhrr42egzm6f4dbc0geb']
//                     },
//                 ],
//             },
//         ]
//     }
// ];


//@TODO: 改成順序方式
/*

tasks: ITask[],
groups: Array<{
    id: string,
    text: string,
    dataLevel: EDataLevel,

    children: Array<{
        id: string,
        text: string
        dataLevel: EDataLevel,

        children: string[]   // task ids (處理順序)
    }>

}>,
 */


export const tasksData: ITaskData[] = [
    {
        'id': 'TASK@1',
        'text': '需求分析',
        'progressRate': 0,
        'estimateWorkTime': 16,
        'estimateStartDate': '2024-07-10',
        'estimateEndDate': '2024-07-20',
        'dataLevel': EDataLevel.Task,
        'status': ETaskStatus.ReadyStart,
        'links': ['TASK@2']
    },
    {
        'id': 'TASK@2',
        'text': '2 新增 登入頁',
        // 'assigners': [assignr.imagine],
        'progressRate': 0,
        'estimateWorkTime': 4,
        'estimateStartDate': '2024-07-28',
        'estimateEndDate': '2024-08-08',
        'status': ETaskStatus.ReadyStart,
        'dataLevel': EDataLevel.Task,
        'links': [
            'TASK@3',
            // '01h63pkhrwms9necc3veac6rc8'
        ]
    },
    {
        'id': 'TASK@3',
        'text': '3 新增 報表-佣金報表',
        'assigners': [assignr.imagine],
        'progressRate': 100,
        'estimateWorkTime': 16,
        'estimateStartDate': '2024-08-09',
        'estimateEndDate': '2024-08-14',
        'status': ETaskStatus.Processing,
        'dataLevel': EDataLevel.Task,
        'links': [
            // 'TASK@4',
        ]
    },
    {
        'id': 'TASK@4',
        'text': '4 新增 報表-存取款',
        'assigners': [assignr.gary],
        'progressRate': 40,
        'estimateWorkTime': 8,
        'estimateStartDate': '2024-08-09',
        'estimateEndDate': '2024-08-14',
        'dataLevel': EDataLevel.Task,
        // 'links': ['01h63pkhrr42egzm6f4dbc0geb']
    },
    {
        'id': 'TASK@5',
        'text': '5 新增 報表-活動報表',
        'assigners': [assignr.gary, assignr.ken],
        'progressRate': 40,
        'estimateWorkTime': 0,
        'estimateStartDate': '2024-08-07',
        'estimateEndDate': '2024-08-08',
        'dataLevel': EDataLevel.Task,
        // 'links': ['TASK@2']
    },
    {
        'id': 'TASK@6',
        'text': '6 新增 註冊頁面',
        'assigners': [assignr.gary],
        'progressRate': 80,
        'estimateWorkTime': 0,
        // 'estimateStartDate': '2024-08-01',
        // 'estimateEndDate': '2024-08-01',
        'dataLevel': EDataLevel.Task,
        // 'links': ['01h63pkhsv5qp5m9zvc9t74930', '01h63pkje0xv3prcc01ttevvn4']
    },
    {
        'id': 'TASK@7',
        'text': '7 新增 錢包-錢包加值',
        'assigners': [assignr.gary],
        'progressRate': 80,
        'estimateWorkTime': 0,
        'estimateStartDate': '2024-08-10',
        'estimateEndDate': '2024-08-11',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@8',
        'text': '8 新增 報表-註冊會員',
        'assigners': [assignr.gary],
        'progressRate': 0,
        'estimateWorkTime': 16,
        'estimateStartDate': '2024-08-11',
        'estimateEndDate': '2024-08-14',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@9',
        'text': '9 新增 Dashoard',
        'assigners': [assignr.gary],
        'progressRate': 40,
        'estimateWorkTime': 16,
        'estimateStartDate': '2024-08-15',
        'estimateEndDate': '2024-08-16',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@10',
        'text': '10 新增 側面選單',
        'assigners': [assignr.gary],
        'progressRate': 30,
        'estimateWorkTime': 8,
        'estimateStartDate': '2024-08-17',
        'estimateEndDate': '2024-08-17',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@11',
        'text': '11 新增 錢包-錢包取款',
        'assigners': [assignr.gary],
        'progressRate': 25,
        'estimateWorkTime': 72,
        'estimateStartDate': '2024-08-18',
        'estimateEndDate': '2024-08-28',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@12',
        'text': '12 新增 獎勵計劃',
        'assigners': [assignr.gary],
        'progressRate': 0,
        'estimateWorkTime': 0,
        'estimateStartDate': '2024-08-23',
        'estimateEndDate': '2024-08-29',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@13',
        'text': '13 新增 首頁',
        'assigners': [assignr.gary],
        'progressRate': 0,
        'estimateWorkTime': 24,
        'estimateStartDate': '2024-09-27',
        'estimateEndDate': '2024-09-29',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@14',
        'text': '14 新增 帳號管理-直屬會員',
        'assigners': [assignr.gary],
        'progressRate': 0,
        'estimateWorkTime': 16,
        'estimateStartDate': '2024-08-30',
        'estimateEndDate': '2024-08-31',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@15',
        'text': '新增 個人選單',
        'assigners': [assignr.gary],
        'progressRate': 0,
        'estimateWorkTime': 4,
        'estimateStartDate': '2024-09-01',
        'estimateEndDate': '2024-09-01',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@16',
        'text': '新增 規則條款',
        'assigners': [assignr.gary],
        'progressRate': 0,
        'estimateWorkTime': 4,
        'estimateStartDate': '2024-09-04',
        'estimateEndDate': '2024-09-04',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@17',
        'text': '新增 合營夥伴-佣金條款',
        'assigners': [assignr.gary],
        'progressRate': 0,
        'estimateWorkTime': 8,
        'estimateStartDate': '2024-09-05',
        'estimateEndDate': '2024-09-06',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@18',
        'text': '新增 市場管理-線下活動',
        'assigners': [assignr.gary],
        'progressRate': 0,
        'estimateWorkTime': 16,
        'estimateStartDate': '2024-09-07',
        'estimateEndDate': '2024-09-08',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@19',
        'text': '新增 聯絡我們',
        'assigners': [assignr.gary],
        'progressRate': 0,
        'estimateWorkTime': 4,
        'estimateStartDate': '2024-09-11',
        'estimateEndDate': '2024-09-11',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@20',
        'text': '新增 帳號管理-直屬代理',
        'assigners': [assignr.gary],
        'progressRate': 0,
        'estimateWorkTime': 16,
        'estimateStartDate': '2024-09-12',
        'estimateEndDate': '2024-09-13',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@21',
        'text': '新增 錢包管理-錢包撥款',
        'assigners': [assignr.gary],
        'progressRate': 0,
        'estimateWorkTime': 0,
        'estimateStartDate': '2024-09-14',
        'estimateEndDate': '2024-09-15',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@22',
        'text': '新增 市長管理-推廣連結',
        'assigners': [assignr.gary],
        'progressRate': 0,
        'estimateWorkTime': 8,
        'estimateStartDate': '2024-09-18',
        'estimateEndDate': '2024-09-18',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@23',
        'text': '新增 錢包管理-錢包歷史紀錄',
        'assigners': [assignr.gary],
        'progressRate': 0,
        'estimateWorkTime': 24,
        'estimateStartDate': '2024-09-19',
        'estimateEndDate': '2024-09-21',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@24',
        'text': '新增 技術支援',
        'assigners': [assignr.gary],
        'progressRate': 0,
        'estimateWorkTime': 8,
        'estimateStartDate': '2024-09-22',
        'estimateEndDate': '2024-09-22',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@25',
        'text': '新增 個人頁面QRCode',
        'assigners': [assignr.gary, assignr.selin],
        'progressRate': 0,
        'estimateWorkTime': 8,
        'estimateStartDate': '2024-09-25',
        'estimateEndDate': '2024-09-26',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@26',
        'text': '新增 修改語系',
        'assigners': [assignr.gary, assignr.selin],
        'progressRate': 0,
        'estimateWorkTime': 4,
        'estimateStartDate': '2024-09-27',
        'estimateEndDate': '2024-09-27',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@27',
        'text': '新增 虛擬錢包資訊',
        'assigners': [assignr.selin],
        'progressRate': 0,
        'estimateWorkTime': 16,
        'estimateStartDate': '2024-09-30',
        'estimateEndDate': '2024-10-03',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
    {
        'id': 'TASK@28',
        'text': '新增 修改密碼',
        'assigners': [assignr.selin],
        'progressRate': 0,
        'estimateWorkTime': 24,
        'estimateStartDate': '2024-10-02',
        'estimateEndDate': '2024-10-04',
        'dataLevel': EDataLevel.Task,
        'links': []
    },
];

export const ganttData: IGroup[] = [
    {
        'id': 'PROJECT@1',
        'text': 'RICH GAME Mobile 改版',
        'dataLevel': EDataLevel.Project,
        'children': [
            {
                'id': 'TEAM@1',
                'text': 'PM',
                'barColor': '#fa6120',
                'dataLevel': EDataLevel.Team,
                'children': ['TASK@1'],
            }
        ]
    },
    {
        'id': 'PROJECT@2',
        'text': '代理後台',
        'dataLevel': EDataLevel.Project,
        'milestones': [
            {date: '2024-08-01', name: '需求洽談'},
            {date: '2024-08-20', name: '結案日'},
            {date: '2024-11-30', name: '請款日'}
        ],
        'children': [
            {
                'id': 'TEAM@1',
                'text': 'Frontend',
                'dataLevel': EDataLevel.Team,
                'barColor': '#00bde5',
                'children': [
                    'TASK@2',
                    'TASK@3',
                    'TASK@4',
                    'TASK@5',
                    'TASK@6',
                    'TASK@7',
                    // 'TASK@8',
                    // 'TASK@9',
                    // 'TASK@10',
                    // 'TASK@11',
                    // 'TASK@12',
                    // 'TASK@13',
                    // 'TASK@14',
                    // 'TASK@15',
                    // 'TASK@16',
                    // 'TASK@17',
                    // 'TASK@18',
                    // 'TASK@19',
                    // 'TASK@20',
                    // 'TASK@21',
                    // 'TASK@22',
                    // 'TASK@23',
                    // 'TASK@24',
                    // 'TASK@25',
                    // 'TASK@26',
                    // 'TASK@27',
                    // 'TASK@28',
                ],
            },

        ]
    }
];


// export const featureData: IData[] = [
//     {
//         text: '專案_1',
//         id: '/project1',
//         dataLevel: EDataLevel.Project,
//         progressRate: 0.65,
//         estimateWorkTime: 8,
//         estimateStartDate: '2024-07-01',
//         estimateEndDate: '2024-10-11',
//         children: [
//             {
//                 text: '產品規劃',
//                 id: '/project1/pm',
//                 dataLevel: EDataLevel.Team,
//                 progressRate: 0.34,
//                 estimateWorkTime: 8,
//                 estimateStartDate: '2024-07-01',
//                 estimateEndDate: '2024-07-11',
//                 children: [
//                     {
//                         text: '需求訪談',
//                         id: '/project1/pm/need',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.5,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-07-01',
//                         estimateEndDate: '2024-07-04',
//                     },
//                     {
//                         text: 'Wireframe製作',
//                         id: '/project1/pm/wireframe',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.1,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-07-05',
//                         estimateEndDate: '2024-07-11',
//                     }
//                 ],
//             },
//             {
//                 text: 'UIUX',
//                 id: '/project1/uiux',
//                 dataLevel: EDataLevel.Team,
//                 progressRate: 0.4,
//                 estimateWorkTime: 5,
//                 estimateStartDate: '2024-08-01',
//                 estimateEndDate: '2024-08-06',
//                 children: [
//                     {
//                         text: '設計風格提案',
//                         id: '/project1/uiux/style',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.2,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-08-01',
//                         estimateEndDate: '2024-08-01',
//                     },
//                     {
//                         text: '客戶確認修改',
//                         id: '/project1/uiux/customer_confirm',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.4,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-08-01',
//                         estimateEndDate: '2024-08-03',
//                     },
//                     {
//                         text: 'Web_PC',
//                         id: '/project1/uiux/pc',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.1,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-08-03',
//                         estimateEndDate: '2024-08-04',
//                     },
//                     {
//                         text: 'Web_APP',
//                         id: '/project1/uiux/app',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.5,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-08-05',
//                         estimateEndDate: '2024-08-06',
//                     }
//                 ],
//             },
//             {
//                 text: '前端開發',
//                 id: '/project1/frontend',
//                 dataLevel: EDataLevel.Team,
//                 progressRate: 0.85,
//                 estimateWorkTime: 8,
//                 estimateStartDate: '2024-09-01',
//                 estimateEndDate: '2024-09-11',
//                 children: [
//                     {
//                         text: '首頁',
//                         id: '/project1/frontend/dashboard',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.34,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-09-01',
//                         estimateEndDate: '2024-09-04',
//                     },
//                     {
//                         text: '註冊頁',
//                         id: '/project1/frontend/sign_up',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.25,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-09-05',
//                         estimateEndDate: '2024-09-06',
//                     },
//
//                 ],
//             },
//             {
//                 text: '後端開發',
//                 id: '/project1/backend',
//                 dataLevel: EDataLevel.Team,
//                 progressRate: 0.8,
//                 estimateWorkTime: 8,
//                 estimateStartDate: '2024-09-07',
//                 estimateEndDate: '2024-09-11',
//                 children: [
//                     {
//                         text: '註冊功能API',
//                         id: '/project1/backend/sign_up',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.12,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-09-20',
//                         estimateEndDate: '2024-09-21',
//                     },
//                     {
//                         text: '登入功能API',
//                         id: '/project1/backend/sign_in',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.5,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-09-22',
//                         estimateEndDate: '2024-10-13',
//                     },
//
//                 ],
//             },
//         ]
//     },
//     {
//         text: '專案_2',
//         id: '/project2',
//         dataLevel: EDataLevel.Project,
//         progressRate: 0.8,
//         estimateWorkTime: 8,
//         estimateStartDate: '2024-07-01',
//         estimateEndDate: '2024-10-11',
//         children: [
//             {
//                 text: '產品規劃',
//                 id: '/project2/pm',
//                 dataLevel: EDataLevel.Team,
//                 progressRate: 0.2,
//                 estimateWorkTime: 8,
//                 estimateStartDate: '2024-07-11',
//                 estimateEndDate: '2024-07-30',
//                 children: [
//                     {
//                         text: '需求訪談',
//                         id: '/project2/pm/need',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.8,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-07-11',
//                         estimateEndDate: '2024-07-20',
//                     },
//                     {
//                         text: 'Wireframe製作',
//                         id: '/project2/pm/wireframe',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.8,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-07-21',
//                         estimateEndDate: '2024-07-30',
//                     }
//                 ],
//             },
//             {
//                 text: 'UIUX',
//                 id: '/project2/uiux',
//                 dataLevel: EDataLevel.Team,
//                 progressRate: 0.8,
//                 estimateWorkTime: 8,
//                 estimateStartDate: '2024-07-01',
//                 estimateEndDate: '2024-08-10',
//                 children: [
//                     {
//                         text: '設計風格提案',
//                         id: '/project2/uiux/style',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.8,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-07-01',
//                         estimateEndDate: '2024-07-11',
//                     },
//                     {
//                         text: '客戶確認修改',
//                         id: '/project2/uiux/customer_confirm',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.8,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-07-11',
//                         estimateEndDate: '2024-07-20',
//                     },
//                     {
//                         text: 'Web_PC',
//                         id: '/project2/uiux/pc',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.8,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-07-21',
//                         estimateEndDate: '2024-07-30',
//                     },
//                     {
//                         text: 'Web_APP',
//                         id: '/project2/uiux/app',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.8,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-08-01',
//                         estimateEndDate: '2024-08-10',
//                     }
//                 ],
//             },
//             {
//                 text: '前端開發',
//                 id: '/project2/frontend',
//                 dataLevel: EDataLevel.Team,
//                 progressRate: 0.8,
//                 estimateWorkTime: 8,
//                 estimateStartDate: '2024-07-01',
//                 estimateEndDate: '2024-07-30',
//                 children: [
//                     {
//                         text: '首頁',
//                         id: '/project2/frontend/dashboard',
//                         dataLevel: EDataLevel.Task,
//
//                         progressRate: 0.8,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-07-01',
//                         estimateEndDate: '2024-07-11',
//                     },
//                     {
//                         text: '註冊頁',
//                         id: '/project2/frontend/sign_up',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.8,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-07-12',
//                         estimateEndDate: '2024-07-21',
//                     },
//
//                 ],
//             },
//             {
//                 text: '後端開發',
//                 id: '/project2/backend',
//                 dataLevel: EDataLevel.Team,
//                 progressRate: 0.8,
//                 estimateWorkTime: 8,
//                 estimateStartDate: '2024-07-01',
//                 estimateEndDate: '2024-07-11',
//                 children: [
//                     {
//                         text: '註冊功能API',
//                         id: '/project2/backend/sign_up',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.8,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-07-01',
//                         estimateEndDate: '2024-07-11',
//                     },
//                     {
//                         text: '登入功能API',
//                         id: '/project2/backend/sign_in',
//                         dataLevel: EDataLevel.Task,
//                         progressRate: 0.8,
//                         estimateWorkTime: 8,
//                         estimateStartDate: '2024-07-01',
//                         estimateEndDate: '2024-07-11',
//                     },
//
//                 ],
//             },
//         ]
//     },
//
//
// ];
