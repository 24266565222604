import {useEffect, useState} from 'react';
import AcroolGantt, {
    TOnTaskChangeDate,
    IGroup,
    IGanttProps,
    ELinkMode,
    TOnInsertTask,
    TOnTaskChangeSequence,
    insertObject,
    sortData,
    modifyData, IBaseData, ITaskData,
    findTaskInGroupIndex,
} from '@acrool/react-gantt';
import dayjs, {Dayjs} from 'dayjs';
import {ganttData, tasksData} from '../data';
import {unique, removeByIndex, insert, push, move} from '@acrool/js-utils/array';
import {ulid} from 'ulid';
import Logger from '@acrool/js-logger';
import {produce} from 'immer';


export enum EDateMode {
    day, week,
}

//
// const deepMerge = <T extends IBaseData>(originProjects: T[], targetProjects: T[]): T[] => {
//     let targetProjectsMap = [...targetProjects];
//     const mergeProjects = originProjects.reduce((res: T[], curr, index) => {
//
//         // 先找 被找到就就刪除
//         const idx = targetProjectsMap.findIndex(row => row.id === curr.id);
//         if(idx >= 0){
//             const targetProject = targetProjectsMap[idx];
//             targetProjectsMap = removeByIndex(targetProjectsMap, idx);
//             if(isNotEmpty(targetProject.children) || isNotEmpty(curr.children)){
//                 return res.concat({
//                     ...curr,
//                     children: deepMerge(targetProject.children ?? [], curr.children ?? []),
//                 });
//             }
//         }
//         return res.concat(curr);
//     }, []);
//
//
//     return [
//         ...mergeProjects,
//         ...targetProjectsMap,
//     ];
// };




function Base() {
    const [count, setCount] = useState(0);
    const [isExpandAll, setExpandAll] = useState(true);
    const [isSkipHoliday, setSkipHoliday] = useState(false);
    const [isFullScreen, setFullScreen] = useState(false);

    const [groups, setGroups] = useState<IGroup[]|undefined>(ganttData);
    const [tasks, setTasks] = useState<ITaskData[]|undefined>(tasksData);
    const [startDate, setStartDate] = useState<Dayjs>(dayjs('2024-04-01'));
    const [endDate, setEndDate] = useState<Dayjs>(dayjs('2024-09-15'));

    const defaultPositionDay = dayjs('2024-08-01');
    // const startDate = dayjs().subtract(2, 'month');
    // const endDate = dayjs().add(6, 'month');


    // useEffect(() => {
    //     setGroups(ganttData);
    //     setTasks(tasksData);
    // }, []);


    const handleSetData = () => {
        Logger.info('handleSetData');
        setGroups(curr => curr ? undefined: ganttData);
        setTasks(curr => curr ? undefined: tasksData);
    };


    const handleSetSkipHoliday = () => {
        Logger.info('handleTaskInsertDate');
        setSkipHoliday(curr => !curr);
    };


    /**
     * 當從資料夾區 新增任務
     * @param taskId
     */
    const handleTaskInsertDate: TOnInsertTask = (taskId) => {
        Logger.info('handleTaskInsertDate', taskId);


        const newId = ulid();
        setGroups(currRow => {
            if(!currRow){
                return currRow;
            }
            const newGroup = produce(currRow, draft => {
                const {projectIndex, teamIndex, taskIndex} = findTaskInGroupIndex(draft, taskId);

                if(draft?.[projectIndex]?.children?.[teamIndex].children){
                    // @ts-ignore
                    draft[projectIndex].children[teamIndex].children = insert(draft[projectIndex].children[teamIndex].children, taskIndex + 1, newId);
                }

                return draft;
            });
            return newGroup;
        });
        // @ts-ignore
        setTasks(curr => {
            const task = curr?.find(row => row.id === taskId);
            return push(curr, {...task, id: newId});
        });
    };


    const handleOnScrollStart = () => {
        Logger.info('handleOnScrollStart');
        setStartDate(curr => curr.subtract(6, 'month'));
    };


    const handleOnScrollEnd = () => {
        Logger.info('handleOnScrollEnd');
        setEndDate(curr => curr.add(6, 'month'));
    };


    /**
     * 當更改任務日期
     * @param dataList
     */
    const handleOnTaskChangeDate: TOnTaskChangeDate = (dataList) => {
        Logger.info('handleTaskChangeDate', dataList);

        setTasks(curr => {
            return produce(curr, draft => {
                dataList.forEach(data => {
                    const task = draft?.find(task => task.id === data.id);
                    if(task){
                        task.estimateStartDate = data.startDate;
                        task.estimateEndDate = data.endDate;
                    }
                });
            });
        });

        // const modifyExtendData = (data: IData[]) => data.map(projectRow => {
        //     return dataList.reduce((curr, data) => {
        //         return {
        //             ...curr,
        //             children: curr.children?.map(teamRow => {
        //                 return {
        //                     ...teamRow,
        //                     children: teamRow.children?.map(taskRow => {
        //                         if (taskRow.id === data.id) {
        //                             return {
        //                                 ...taskRow,
        //                                 estimateStartDate: data.startDate,
        //                                 estimateEndDate: data.endDate,
        //                             };
        //                         }
        //                         return taskRow;
        //                     })
        //                 };
        //             })
        //         };
        //     }, projectRow);
        // });
        //
        //
        // setData(curr => {
        //     if(curr){
        //         return modifyExtendData(curr);
        //     }
        //     return undefined;
        // });

    };



    /**
     * 加入連結
     * @param args
     */
    const handleAddLink: IGanttProps['onLinkAdd'] = (args) => {
        Logger.info('handleAddLink', args);
        setTasks(curr => {
            return produce(curr, draft => {
                const fromTask = draft?.find(task => task.id === args.from);
                if(fromTask){
                    fromTask.links?.push(args.to) ?? (fromTask.links = [args.to]);
                }
            });
        });

        // // @ts-ignore
        // const newData = modifyData(tasksData, task => {
        //     if(task.id === args.from){
        //         return {
        //             ...task,
        //             links: unique([...(task.links ?? []), args.to]) as string[],
        //         };
        //     }
        //     return task;
        // });
        // setTasks(newData);
    };


    /**
     * 刪除連結
     * @param args
     */
    const handleDeleteLink: IGanttProps['onLinkDelete'] = (id, mode) => {
        Logger.info('handleDeleteLink', id, mode);
        setTasks(curr => {
            return produce(curr, draft => {
                const task = draft?.find(task => task.id === id);
                if(task) {
                    switch (mode) {
                    case ELinkMode.to:
                        task.links = undefined;
                        break;

                    case ELinkMode.from:
                        if (task.links) {
                            const deleteIndex = task.links.findIndex(linkId => linkId === id);
                            if (deleteIndex >= 0) {
                                task.links = removeByIndex(task.links, deleteIndex) as string[] | undefined;
                            }
                        }
                        break;
                    }
                }

            });
        });
        

    };


    /**
     * 更改順序
     * @param id
     * @param atIndex
     */
    const handleOnTaskChangeSequence: TOnTaskChangeSequence = (id, atIndex) => {
        Logger.info('handleOnTaskChangeSequence', id, atIndex);


        setGroups(curr => {
            const newGroup = produce(curr, draft => {
                if(!draft){
                    return undefined;
                }

                const {projectIndex, teamIndex, taskIndex} = findTaskInGroupIndex(draft, id);


                // console.log('call api update',id, draft[projectIndex].children[teamIndex].children[atIndex], taskIndex, atIndex, atIndex > taskIndex);

                if(draft?.[projectIndex]?.children?.[teamIndex].children){
                    // @ts-ignore
                    draft[projectIndex].children[teamIndex].children = move(draft[projectIndex].children[teamIndex].children, taskIndex, atIndex);
                }


                return draft;
            });
            return newGroup;
        });


        // setGroups(generateSortData(dropAreaKey, index, toIndex));


        // setData(sortData(data, (tasks) => {
        //     const index = tasks.findIndex(task => task.id === id);
        //     if(tasks[index]){
        //         return update(tasks, {
        //             $splice: [
        //                 [index, 1],
        //                 [atIndex, 0, tasks[index]],
        //             ],
        //         });
        //     }
        //
        //     return tasks;
        // }));

    };


    return <>
        <div style={{position: 'fixed', top: 0, left: 0}}>
            <button type="button" className="mr-2" onClick={() => setCount(curr => curr + 1)}>Count {count}</button>
            <button type="button" className="mr-2" onClick={() => setExpandAll(curr => !curr)}>isExpandAll: {String(isExpandAll)}</button>
            <button type="button" className="mr-2" onClick={handleSetSkipHoliday}>isSkipHoliday: {String(isSkipHoliday)}</button>
            <button type="button" className="mr-2" onClick={handleSetData}>Set Data</button>
            <span className="mr-2">{startDate.format('YYYY-MM-DD')} ~ {endDate.format('YYYY-MM-DD')}</span>
        </div>

        <AcroolGantt
            // key={params.mode}
            // data={data}
            data={{
                projectMap: groups,
                tasks,
            }}
            // groups={groups}
            // tasks={tasks}
            // onTaskChangeDate={handleDate}
            // onClickTask={id => navigate({hash: `/task/${id}`})}
            // onChangeGroupMode={(mode) => {}}
            isExpandAll={isExpandAll}
            preVisibleDay={startDate}
            nextVisibleDay={endDate}

            onScrollStart={handleOnScrollStart}
            onScrollEnd={handleOnScrollEnd}

            onLinkAdd={handleAddLink}
            onLinkDelete={handleDeleteLink}
            isSkipHoliday={isSkipHoliday}

            isVisibleStatus={true}

            isVisibleProgressRate={true}
            isVisibleWorkTime
            isVisibleEstimateDate={true}


            onInsertTask={handleTaskInsertDate}
            onTaskChangeDate={handleOnTaskChangeDate}
            onTaskChangeSequence={handleOnTaskChangeSequence}
            // onClickTask={id => console.log('onClickTask', id)}
            defaultPositionDay={defaultPositionDay}

            isFullScreen={isFullScreen}
            onFullScreen={() => setFullScreen(curr => !curr)}
        />
    </>;

}

export default Base;



