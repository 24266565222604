
export const GROUPS = [
    {
        id: 'g1',
        text: 'Frontend',
        taskIds: ['4', '5', '6', '7', '8', '9', '10'],
    },
    {
        id: 'g2',
        text: 'Backend',
        taskIds: ['1', '2', '3'],
    },
];



export const ITEMS = [
    {
        id: '1',
        groupId: 'g1',
        text: 'Write a cool JS library',
    },
    {
        id: '2',
        groupId: 'g1',
        text: 'Make it generic enough',
    },
    {
        id: '3',
        groupId: 'g1',
        text: 'Write README',
    },



    {
        id: '4',
        groupId: 'g2',
        text: '1 Create some examples',
    },
    {
        id: '5',
        groupId: 'g2',
        text: '2 Spam in Twitter and IRC to promote it',
    },
    {
        id: '6',
        groupId: 'g2',
        text: '3 ???',
    },
    {
        id: '7',
        groupId: 'g2',
        text: '4 PROFIT',
    },
    {
        id: '8',
        groupId: 'g2',
        text: '5 PROFIT3',
    },
    {
        id: '9',
        groupId: 'g2',
        text: '6 PROFIT4',
    },
    {
        id: '10',
        groupId: 'g2',
        text: '7 PROFIT5',
    },
    {
        id: '11',
        groupId: 'g2',
        text: '8 PROFIT6',
    },
];
