import styled from 'styled-components';
import {DndProvider, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {memo, useState, useCallback} from 'react';
import {ItemTypes} from './ItemTypes';
import {Card} from './Card';
import {GROUPS, ITEMS} from './data';
import {produce} from 'immer';
import {move} from '@acrool/js-utils/array';


const style = {
    width: 400,
};

export interface ContainerState {
    cards: any[]
}



export const Container = memo(function Container() {
    const [cards, setCards] = useState(ITEMS);
    const [groups, setGroups] = useState(GROUPS);

    const findCard = useCallback(
        (id: string) => {
            const groupIndex = groups.findIndex(row => row.taskIds.includes(id));
            const index = groups[groupIndex].taskIds.findIndex(row => row === id);
            const card = cards.find(row => row.id === id);

            return {
                card,
                groupIndex,
                index,
            };
        },
        [groups, cards],
    );

    const moveCard = useCallback(
        (id: string, atIndex: number) => {

            setGroups(curr => {
                const newGroup = produce(curr, draft => {

                    const {card,groupIndex, index} = findCard(id);
                    if(card){
                        // draft[groupIndex]?.taskIds
                        //     .splice(index, 1)
                        //     .splice(atIndex, 0, card.id);

                        draft[groupIndex].taskIds = move(draft[groupIndex].taskIds, index, atIndex);

                    }



                    // move(draft[d].taskIds, index, atIndex);
                    return draft;
                });
                return newGroup;
            });




            // setGroups(newGroup);


            // setGroups(
            //     update(card.id, {
            //         $splice: [
            //             [index, 1],
            //             [atIndex, 0, card],
            //         ],
            //     }),
            // );
        },
        [findCard, groups, cards, setCards],
    );

    const [, drop] = useDrop(() => ({accept: ItemTypes.CARD}));
    return (
        <div ref={drop} style={style} >
            {groups.map((group) => {

                return <div className="pb-5" key={group.id}>

                    {
                        group.taskIds.map(taskId => {

                            // console.log(cards);
                            const card = cards.find(row => row.id === taskId);
                            if(!card) {
                                return undefined;
                            }
                            return <Card
                                key={card.id}
                                id={`${card.id}`}
                                text={card.text}
                                groupId={card.groupId}
                                moveCard={moveCard}
                                findCard={findCard}
                            />;
                        }).filter(row => !!row)
                    }
                </div>;

            })}
        </div>
    );
});



function Sortable() {


    return <SortableRoot>
        <DndProvider backend={HTML5Backend}>
            <Container/>
        </DndProvider>

    </SortableRoot>;

}

export default Sortable;



const SortableRoot = styled.div`
    background-color: #2e9b33;
  color: #000;
  width: 100%;
  height: 100%;
  sortable: scroll;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

