import {useState} from 'react';
import styled, {css} from 'styled-components';
import './App.css';

import Base from './sample/Base';
import {GridThemeProvider} from '@acrool/react-grid';
import Overflow from './sample/Overflow';
import DragLine from './sample/DragLine';
import Sortable from './sample/Sortable';
import Sortable2 from './sample/Sortable2';
import Banner from "./components/Banner";




enum EExampleCode {
    base = 'base',
    overflow = 'overflow',
    dragLine = 'dragLine',
    sortable = 'sortable',
    sortable2 = 'sortable2',
}


const examples: Record<EExampleCode, () => JSX.Element> = {
    [EExampleCode.base]: Base,
    [EExampleCode.overflow]: Overflow,
    [EExampleCode.dragLine]: DragLine,
    [EExampleCode.sortable]: Sortable,
    [EExampleCode.sortable2]: Sortable2,
};

function App() {
    const [exampleKey, setExampleItem] = useState<EExampleCode>(EExampleCode.base);
    const Comp = examples[exampleKey];



    return (
        <GridThemeProvider>
            <AppRoot className="App">
                <Banner />

                <Content>



                    <Comp/>

                    {/*<Sortable2/>*/}
                </Content>


            </AppRoot>
        </GridThemeProvider>
    );
}

export default App;



const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 80vh;
  background: #fff;
`;


const AppRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
