import styled from 'styled-components';







export enum EDateMode {
    day, week,
}


function Overflow() {


    return <OverflowRoot>

        <Over>
            <OverItem1/>
            <OverItem2/>
        </Over>
    </OverflowRoot>;

}

export default Overflow;


const OverItem1 = styled.div`
  background-color: #55a9ff;
  width: 2400px;
  height: 10px;
  
  width: 100%;
`;
const OverItem2 = styled.div`
  background-color: rgba(61, 134, 98, 0.98);
  width: 7400px;
  height: 10px;
`;

const Over = styled.div`
    background-color: #747bff;
  width: fit-content;
  height: 100%;
  flex: 1;
  align-self: stretch;
`;

const OverflowRoot = styled.div`
    background-color: #2e9b33;
  width: 100%;
  height: 100%;
  overflow: scroll;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

