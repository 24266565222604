import styled from 'styled-components';
import {DndProvider, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {memo, useState, useCallback} from 'react';
import {ItemTypes} from './ItemTypes';
import {Card} from './Card';
import {produce} from 'immer';
import {move} from '@acrool/js-utils/array';
import {ganttData, tasksData} from '../../data';
import {IGroup, ITaskData} from '@acrool/react-gantt';


const style = {
    width: 400,
};

export interface ContainerState {
    cards: any[]
}



export const Container = memo(function Container(data: { projectMap: IGroup[], tasks: ITaskData[] }) {
    const [cards, setCards] = useState(data.tasks);
    const [groups, setGroups] = useState(data.projectMap);

    const findCard = useCallback(
        (id: string) => {
            const groupIndex = groups.findIndex(project => {
                return project.children?.find(team => team.children?.includes(id));
            });
            const teamIndex = groups[groupIndex].children?.findIndex(team => team.children?.includes(id)) ?? -1;
            const index = groups[groupIndex].children?.[teamIndex]?.children?.findIndex(row => row === id) ?? -1;
            const card = cards.find(row => row.id === id);

            return {
                card,
                groupIndex,
                teamIndex,
                index,
            };
        },
        [groups, cards],
    );

    const moveCard = useCallback((id: string, atIndex: number) => {

        setGroups(curr => {
            const newGroup = produce(curr, draft => {

                const {card,groupIndex, teamIndex, index} = findCard(id);
                if(card){
                    // draft[groupIndex]?.taskIds
                    //     .splice(index, 1)
                    //     .splice(atIndex, 0, card.id);

                    if(draft[groupIndex].children?.[teamIndex].children){
                        const teams = draft[groupIndex].children;

                        if(teams && typeof teams[teamIndex]?.children !== 'undefined'){
                            teams[teamIndex].children = move(teams[teamIndex].children ?? [], index, atIndex);
                        }
                    }

                }



                // move(draft[d].taskIds, index, atIndex);
                return draft;
            });
            return newGroup;
        });




        // setGroups(newGroup);


        // setGroups(
        //     update(card.id, {
        //         $splice: [
        //             [index, 1],
        //             [atIndex, 0, card],
        //         ],
        //     }),
        // );
    },
    [findCard, groups, cards, setCards],
    );

    const [, drop] = useDrop(() => ({accept: ItemTypes.CARD}));
    return (
        <div ref={drop} style={style}>
            {groups?.map((project) => {

                return <div className="pb-5" key={`project_${project.id}`} data-id={project.id}>

                    {
                        project.children?.map(team => {

                            return team.children?.map(taskId => {

                                const card = cards.find(row => row.id === taskId);
                                if(!card) {
                                    return undefined;
                                }
                                return <Card
                                    data-id={card.id}
                                    key={card.id}
                                    id={`${card.id}`}
                                    text={card.text}
                                    groupId={'A1'}
                                    moveCard={moveCard}
                                    findCard={findCard}
                                />;
                            });
                            // console.log(cards);

                        })
                            .filter(row => !!row)
                    }
                </div>;

            })}
        </div>
    );
});



function Sortable2() {


    return <SortableRoot>
        <DndProvider backend={HTML5Backend}>
            <Container projectMap={ganttData} tasks={tasksData}/>
        </DndProvider>

    </SortableRoot>;

}

export default Sortable2;



const SortableRoot = styled.div`
    background-color: #2e9b33;
  color: #000;
  width: 100%;
  height: 100%;
  sortable: scroll;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

